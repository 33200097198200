import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Seo from "../components/seo"

// css
const Root = styled.div`
  padding: 40px 0 100px 0;
  display: flex;
  justify-content: center;
  width: 100%;
`

const Container = styled.div`
  width: 680px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 799px) {
    max-width: 100%;
  }
`

const Title = styled.h1`
  margin-bottom: 6px;
  color: #222222;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 45px;
`

const Contents = styled.div`
  h1 {
    margin: 40px 0 20px 0;
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
  }
  p {
    margin: 0;
    color: #222222;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 32px;
  }
`

const Sign = styled.div`
  p {
    margin: 40px 0 0 0;
    color: #222222;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: right;
  }
`

// component
const PrivacyPolicy = ({ data }) => {
  const privacyData = data.allContentfulPrivacyPolicy.edges[0].node
  const contentsHTML = privacyData.contents.childMarkdownRemark.html
  const signHTML = privacyData.sign.childMarkdownRemark.html

  return (
    <div>
      <Seo
        title="プライバシーポリシー"
        description="株式会社アクアのプライバシーポリシーです。"
      />

      <Root>
        <Container>
          <Title>プライバシーポリシー</Title>

          <Contents dangerouslySetInnerHTML={{ __html: contentsHTML }} />
          <Sign dangerouslySetInnerHTML={{ __html: signHTML }} />
        </Container>
      </Root>
    </div>
  )
}

export const query = graphql`
  query PrivacyQuery {
    allContentfulPrivacyPolicy {
      edges {
        node {
          title
          contents {
            childMarkdownRemark {
              html
            }
          }
          sign {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

export default PrivacyPolicy
